import React, {useState, useRef} from "react";
import ReCAPTCHA from "react-google-recaptcha";
// create contact component
import Layout from "../components/layout";
import axios from 'axios';
import SEO from "../components/seo";

const Contact = ({data}) => {
  const recaptchaRef = useRef(null);
  const [feedback, setFeedback] = useState({name: "", email: "", message: ""});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFeedback({ ...feedback, [id]: value });
  }
  const [alert, setAlert] = useState({
    message: "",
    type: ""
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    const data = {...feedback, captchaToken};
    const response = await axios.post("/api/feedback", data);
    const alertType = response.status === 200 ? "secondary" : "primary";
    if(alertType === "secondary") setIsSubmitted(true);
    setAlert({message: response.data.message, type: alertType});
  }
  return (
    <Layout>
      <SEO title="Контакти" />
      <div className="container">
        <h1>Контакти</h1>
        <p>
        Адрес 1261 София
        Телефон +359 878272100
        Имейл info@bellagourmet.eu
        Ако имате проблем или искате да попитате нещо, моля проверете дали няма да намерите търсената от Вас информация в секция
        FAQ (Често Задавани Въпроси)
        </p>
        {/* create simple feeback form */}
        <h2>Форма за обратна връзка</h2>
        {alert && (<div className={`alert alert-${alert.type}`} role="alert">{alert.message}</div>)}
        {!isSubmitted && (
          <form onChange={handleChange} onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name">Име</label>
              <input type="text" className="form-control" id="name" required />
              <label htmlFor="email">Email адрес</label>
              <input
                type="email"
                className="form-control"
                id="email"
                aria-describedby="emailHelp"
                placeholder="Enter email"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Съобщение</label>
              <textarea className="form-control" id="message" rows="3" required></textarea>
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.GATSBY_RECAPTCHA_V3_SITE_KEY}
              size="invisible"
            />
            <button 
              id="submit_feedback"
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}>Изпрати
            </button>

          </form>
        )}
      </div>
    </Layout>
  );
}

export default Contact;
